<template>
  <div class="projects-component">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <!-- <div class="fill-area flexbox-item-grow"> -->
    <Widget class="full-height-widget">
      <DataTable
        ref="projectsDataTable"
        class="full-height-data-table flexbox-item-grow p-datatable-gridlines p-datatable-sm"
        :value="getProjects"
        :filters.sync="filters"
        stateStorage="local"
        stateKey="projects-table-filters"
        :loading="loading"
        :scrollable="true"
        scrollHeight="620px"
        :sortField.sync="sortField"
        :sortOrder.sync="sortOrder"
        :rows="1000"
        dataKey="id"
        :rowHover="true"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 50, 100, 1000]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
        @filter="onFilter"
      >
        <template #header>
          <div class="table-header">
            <div class="table-header-left">
              <h1 class="page-title">Projekte</h1>
            </div>
            <div class="table-header-right">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText type="text" v-model="filters['global']" :placeholder="$t('search')" />
              </span>

              <div>
                <multiselect
                  v-model="filters['installation.electricMeterChangedAt']"
                  :options="optionsYesNo()"
                  :placeholder="$t('electricMeterChanged')"
                  :show-labels="true"
                  :taggable="false"
                  :deselectLabel="$t('deselectLabel')"
                  selectLabel=""
                  selectedLabel=""
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <b-badge :variant="yesNoColor(option)">
                      {{ $t(option) }}
                    </b-badge>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <b-badge :variant="yesNoColor(option)"> {{ $t(option) }} </b-badge>
                  </template>
                </multiselect>
              </div>

              <div>
                <multiselect
                  v-model="filters['projectState']"
                  :options="optionsProjectState()"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :multiple="true"
                  :taggable="true"
                  :placeholder="$t('projectState')"
                  :show-labels="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <b-badge :variant="projectStateColor(option)">
                      {{ $t(option) }}
                    </b-badge>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <b-badge :variant="projectStateColor(option)">
                      {{ $t(option) }}
                    </b-badge>
                  </template>
                  <template slot="tag" slot-scope="{ option, remove }">
                    <b-badge :variant="projectStateColor(option)">
                      {{ $t(option) }}
                      <i
                        @keypress.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                      ></i>
                    </b-badge>
                  </template>
                </multiselect>
              </div>

              <div>
                <multiselect
                  v-model="filters['employer.name']"
                  :options="employerNames()"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :multiple="true"
                  :taggable="true"
                  :placeholder="$t('employer')"
                  :show-labels="false"
                >
                </multiselect>
              </div>

              <div v-if="isAdmin">
                <multiselect
                  v-model="filters['client.name']"
                  :options="clientNames()"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :multiple="true"
                  :taggable="true"
                  :placeholder="$t('client')"
                  :show-labels="false"
                >
                </multiselect>
              </div>

              <button class="btn btn-inverse mb-2" @click.prevent="onRemoveFilters()">
                <i class="fa fa-remove mr-3"></i>
                {{ $t('remove_filters') }}
              </button>
              <button
                v-if="isAdmin"
                class="btn btn-inverse mb-2"
                @click.prevent="openProjectsExport()"
              >
                <i class="fa fa-download mr-3"></i>
                {{ $t('projects_export') }}
              </button>
              <router-link class="btn btn-inverse mb-2" :to="{ name: 'ProjectCreatePage' }">
                <i class="fa fa-plus-circle mr-2"></i>
                {{ $t('project_create') }}
              </router-link>
            </div>
          </div>
        </template>

        <template #empty>
          {{ $t('no_data_found') }}
        </template>
        <template #loading>
          {{ $t('loading') }}
        </template>

        <Column
          v-if="isAdmin"
          field="number"
          header="#"
          sortable
          :headerStyle="{ width: '60px' }"
          :bodyStyle="'text-align: center;width: 60px'"
        >
          <template #body="slotProps">
            <router-link
              v-if="slotProps.data.number"
              :to="{
                name: 'ProjectEditPage',
                params: { projectNumber: slotProps.data.number },
              }"
              >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
            >
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['number']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="clientProjectNumber"
          header="#"
          sortable
          :headerStyle="{ width: '60px' }"
          :bodyStyle="'text-align: center;width: 60px'"
        >
          <template #body="slotProps">
            <router-link
              v-if="slotProps.data.number && slotProps.data.clientProjectNumber"
              :to="{
                name: 'ProjectEditPage',
                params: { projectNumber: slotProps.data.number },
              }"
              >{{ slotProps.data.clientProjectNumber.toString().padStart(4, '0') }}</router-link
            >
            <span v-else></span>
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['number']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="deliveryAt"
          :header="$t('deliveryAt_short')"
          sortable
          filterMatchMode="custom"
          :filterFunction="filterDateFrom"
          :headerStyle="{ width: '80px' }"
          :bodyStyle="'text-align: center;width: 80px'"
        >
          <template #body="slotProps">
            <span v-if="slotProps.data.deliveryAt">
              {{ $date(slotProps.data.deliveryAt) }}
            </span>
          </template>
          <template #filter>
            <Calendar
              v-model="filters['deliveryAt']"
              selectionMode="single"
              class="p-column-filter"
              dateFormat="dd.mm.yy"
              placeholder=">="
            >
            </Calendar>
          </template>
        </Column>

        <Column
          field="customer.lastname"
          :header="$t('lastname')"
          sortable
          filterMatchMode="contains"
          :bodyStyle="'text-align: right'"
        >
          <template #filter>
            <InputText type="text" v-model="filters['customer.lastname']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="customer.firstname"
          :header="$t('firstname')"
          sortable
          filterMatchMode="contains"
          :bodyStyle="'text-align: right'"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['customer.firstname']"
              class="p-column-filter"
            />
          </template>
        </Column>

        <Column
          field="customer.zip"
          :header="$t('zip')"
          sortable
          filterMatchMode="startsWith"
          :headerStyle="{ width: '60px' }"
          :bodyStyle="'text-align: center;width: 60px'"
        >
          <template #filter>
            <InputText type="text" v-model="filters['customer.zip']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="customer.city"
          :header="$t('city')"
          sortable
          filterMatchMode="contains"
          :bodyStyle="'text-align: right'"
        >
          <template #filter>
            <InputText type="text" v-model="filters['customer.city']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="initialInformationSent"
          :header="$t('initialInformationSent')"
          sortable
          filterMatchMode="equals"
          :headerStyle="{ width: '65px' }"
          :bodyStyle="'text-align: center;width: 65px'"
        >
          <template #body="slotProps">
            <b-badge :variant="yesNoColor(slotProps.data.initialInformationSent)">
              {{ $t(slotProps.data.initialInformationSent) }}
            </b-badge>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['initialInformationSent']"
              :options="optionsYesNo()"
              :placeholder="$t('apply_filter')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <b-badge :variant="yesNoColor(slotProps.option)">
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="powerCompany.company"
          :header="$t('powerCompany')"
          sortable
          filterMatchMode="contains"
          :bodyStyle="'text-align: right'"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['powerCompany.company']"
              class="p-column-filter"
            />
          </template>
        </Column>

        <Column
          field="solarPlant.solarPlantOverallPower"
          :header="$t('solarPlantOverallPower_short')"
          sortable
          filterMatchMode="gte"
          :headerStyle="{ width: '60px' }"
          :bodyStyle="'text-align: center;width: 60px'"
        >
          <template #body="slotProps">
            {{ $n(slotProps.data.solarPlant.solarPlantOverallPower, 'decimal_triple') }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['solarPlant.solarPlantOverallPower']"
              class="p-column-filter"
              placeholder=">="
            />
          </template>
        </Column>

        <Column
          field="latestInstallationAppointment.installationTeam"
          :header="$t('installationTeam_short')"
          sortable
          filterMatchMode="equals"
          :headerStyle="{ width: '90px' }"
          :bodyStyle="'text-align: center;width: 90px'"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.latestInstallationAppointment &&
                  slotProps.data.latestInstallationAppointment.installationTeam
              "
            >
              {{ $t(slotProps.data.latestInstallationAppointment.installationTeam) }}
            </span>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['latestInstallationAppointment.installationTeam']"
              :options="getInstallationTeams"
              :placeholder="$t('apply_filter')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                {{ $t(slotProps.option) }}
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="latestInstallationAppointment.installationAt"
          :header="$t('installationAt_short')"
          sortable
          filterMatchMode="custom"
          :filterFunction="filterDateFrom"
          :headerStyle="{ width: '110px' }"
          :bodyStyle="'text-align: center;width: 110px'"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.latestInstallationAppointment &&
                  slotProps.data.latestInstallationAppointment.installationAt
              "
            >
              <b-badge
                :variant="
                  yesNoColor(slotProps.data.latestInstallationAppointment.appointmentApprovedState)
                "
              >
                {{ $datetime(slotProps.data.latestInstallationAppointment.installationAt) }}
              </b-badge>
            </span>
          </template>
          <template #filter>
            <Calendar
              v-model="filters['latestInstallationAppointment.installationAt']"
              selectionMode="single"
              class="p-column-filter"
              dateFormat="dd.mm.yy"
              placeholder=">="
            >
            </Calendar>
          </template>
        </Column>

        <Column
          field="latestInstallationAppointment.installationType"
          :header="$t('installationType_short')"
          sortable
          filterMatchMode="equals"
          :headerStyle="{ width: '60px' }"
          :bodyStyle="'text-align: center;width: 60px'"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.latestInstallationAppointment &&
                  slotProps.data.latestInstallationAppointment.installationType
              "
            >
              {{ $t(slotProps.data.latestInstallationAppointment.installationType + '_short') }}
            </span>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['latestInstallationAppointment.installationType']"
              :options="getInstallationTypes"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                {{ $t(slotProps.option) }}
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="installation.originalInstallationType"
          :header="$t('originalInstallationType_short')"
          sortable
          filterMatchMode="equals"
          :headerStyle="{ width: '60px' }"
          :bodyStyle="'text-align: center;width: 60px'"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.installation && slotProps.data.installation.originalInstallationType
              "
            >
              {{ $t(slotProps.data.installation.originalInstallationType + '_short') }}
            </span>
          </template>

          <template #filter>
            <Dropdown
              v-model="filters['installation.originalInstallationType']"
              :options="getOriginalInstallationTypes"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                {{ $t(slotProps.option) }}
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="netRegistrationRequirementsFullfilledState"
          :header="$t('netRegistrationRequirementsFullfilledState')"
          sortable
          filterMatchMode="equals"
          :headerStyle="{ width: '65px' }"
          :bodyStyle="'text-align: center;width: 65px'"
        >
          <template #body="slotProps">
            <b-badge
              :variant="yesNoColor(slotProps.data.netRegistrationRequirementsFullfilledState)"
            >
              {{ $t(slotProps.data.netRegistrationRequirementsFullfilledState) }}
            </b-badge>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['netRegistrationRequirementsFullfilledState']"
              :options="optionsYesNo()"
              :placeholder="$t('apply_filter')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <b-badge :variant="yesNoColor(slotProps.option)">
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="netRegistrationSentAt"
          :header="$t('netRegistrationSentAt_short')"
          sortable
          filterMatchMode="custom"
          :filterFunction="filterDate"
          :headerStyle="{ width: '85px' }"
          :bodyStyle="'text-align: center;width: 85px'"
        >
          <template #body="slotProps">
            <span v-if="slotProps.data.netRegistrationSentAt">
              {{ $date(slotProps.data.netRegistrationSentAt) }}
            </span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['netRegistrationSentAt']"
              class="p-column-filter"
            />
          </template>
        </Column>

        <!-- <Column
          field="operationRegistrationRequirementsFullfilledState"
          :header="$t('operationRegistrationRequirementsFullfilledState')"
          sortable
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <b-badge
              :variant="yesNoColor(slotProps.data.operationRegistrationRequirementsFullfilledState)"
            >
              {{ $t(slotProps.data.operationRegistrationRequirementsFullfilledState) }}
            </b-badge>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['operationRegistrationRequirementsFullfilledState']"
              :options="optionsYesNo()"
              :placeholder="$t('apply_filter')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <b-badge :variant="yesNoColor(slotProps.option)">
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
            </Dropdown>
          </template>
        </Column> -->

        <Column
          field="operationRegistrationSentAt"
          :header="$t('operationRegistrationSentAt_short')"
          sortable
          filterMatchMode="custom"
          :filterFunction="filterDate"
          :headerStyle="{ width: '85px' }"
          :bodyStyle="'text-align: center;width: 85px'"
        >
          <template #body="slotProps">
            <span v-if="slotProps.data.operationRegistrationSentAt">
              {{ $date(slotProps.data.operationRegistrationSentAt) }}
            </span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['operationRegistrationSentAt']"
              class="p-column-filter"
            />
          </template>
        </Column>

        <!-- <Column
          field="itemPlanningFinishedState"
          :header="$t('itemPlanningFinishedState_short')"
          sortable
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <b-badge :variant="yesNoColor(slotProps.data.itemPlanningFinishedState)">
              {{ $t(slotProps.data.itemPlanningFinishedState) }}
            </b-badge>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['itemPlanningFinishedState']"
              :options="optionsYesNo()"
              :placeholder="$t('apply_filter')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <b-badge :variant="yesNoColor(slotProps.option)">
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
            </Dropdown>
          </template>
        </Column> -->

        <Column
          field="installation.electricMeterChangedAt"
          :header="$t('electricMeterChangedAt_short')"
          sortable
          filterMatchMode="custom"
          :filterFunction="filterYesNo"
          :headerStyle="{ width: '85px' }"
          :bodyStyle="'text-align: center;width: 85px'"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.installation && slotProps.data.installation.electricMeterChangedAt
              "
            >
              {{ $date(slotProps.data.installation.electricMeterChangedAt) }}
            </span>
          </template>
          <!-- <template #filter>
            <Calendar
              v-model="filters['installation.electricMeterChangedAt']"
              selectionMode="single"
              class="p-column-filter"
              dateFormat="dd.mm.yy"
              placeholder=">="
            >
            </Calendar>
          </template> -->
        </Column>

        <Column
          field="projectState"
          :header="$t('projectState')"
          sortable
          filterMatchMode="in"
          :headerStyle="{ width: '115px' }"
          :bodyStyle="'text-align: center;width: 115px'"
        >
          <template #body="slotProps">
            <b-badge :variant="projectStateColor(slotProps.data.projectState)">
              {{ $t(slotProps.data.projectState) }}
            </b-badge>
          </template>
          <!-- <template #filter>
            <Dropdown
              v-model="filters['projectState']"
              :options="optionsProjectState()"
              :placeholder="$t('apply_filter')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <b-badge :variant="projectStateColor(slotProps.option)">
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
            </Dropdown>
          </template> -->
        </Column>

        <Column
          field="employer.name"
          :header="$t('EMPLOYER_SHORT')"
          sortable
          filterMatchMode="in"
          :bodyStyle="'text-align: right'"
        >
        </Column>
        <Column
          v-if="isAdmin"
          field="client.name"
          :header="$t('client')"
          sortable
          filterMatchMode="in"
          :bodyStyle="'text-align: right'"
        >
        </Column>

        <Column
          field="powerCompany.netRegistrationDocumentsApprovedState"
          :header="$t('netRegistrationDocumentsApprovedState_short')"
          sortable
          filterMatchMode="equals"
          :headerStyle="{ width: '65px' }"
          :bodyStyle="'text-align: center;width: 65px'"
        >
          <template #body="slotProps">
            <b-badge
              v-if="slotProps.data.powerCompany"
              :variant="
                yesNoColor(slotProps.data.powerCompany.netRegistrationDocumentsApprovedState)
              "
            >
              {{ $t(slotProps.data.powerCompany.netRegistrationDocumentsApprovedState) }}
            </b-badge>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['powerCompany.netRegistrationDocumentsApprovedState']"
              :options="optionsYesNo()"
              :placeholder="$t('apply_filter')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <b-badge :variant="yesNoColor(slotProps.option)">
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="powerCompany.operationRegistrationDocumentsApprovedState"
          :header="$t('operationRegistrationDocumentsApprovedState_short')"
          sortable
          filterMatchMode="equals"
          :headerStyle="{ width: '65px' }"
          :bodyStyle="'text-align: center;width: 65px'"
        >
          <template #body="slotProps">
            <b-badge
              v-if="slotProps.data.powerCompany"
              :variant="
                yesNoColor(slotProps.data.powerCompany.operationRegistrationDocumentsApprovedState)
              "
            >
              {{ $t(slotProps.data.powerCompany.operationRegistrationDocumentsApprovedState) }}
            </b-badge>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['powerCompany.operationRegistrationDocumentsApprovedState']"
              :options="optionsYesNo()"
              :placeholder="$t('apply_filter')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <b-badge :variant="yesNoColor(slotProps.option)">
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
            </Dropdown>
          </template>
        </Column>
      </DataTable>
    </Widget>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { getYesNoStateColor, getProjectStateColor } from '@/helpers/colors';
import { yesNo, projectState } from '@/helpers/enums';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import isEqual from 'lodash/isEqual';
import Multiselect from 'vue-multiselect';

/**
 * Table features:
 * column filter *
 * column sort *
 * cell editing
 * pagination *
 * overall search *
 * export
 * row templates *
 * add/remove columns
 * loading state *
 * fixed header ???
 */

export default {
  name: 'ProjectsPage',
  components: {
    Breadcrumbs,
    DataTable,
    Column,
    Widget,
    InputText,
    Dropdown,
    Calendar,
    Multiselect,
  },
  data() {
    return {
      loading: true,
      filters: {},
      sortField: null,
      sortOrder: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'isLoading',
      'getProjects',
      'getInstallationTeams',
      'getInstallationTypes',
      'getOriginalInstallationTypes',
      'getProjectStates',
      'getEmployers',
      'getClients',
    ]),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('projects') }];
    },
  },
  methods: {
    ...mapActions([
      'fetchProjects',
      'fetchProjectList',
      'fetchProjectsExportUrl',
      'fetchInstallationTeams',
      'fetchInstallationTypes',
      'fetchOriginalInstallationTypes',
      'updateUserSettings',
      'fetchProjectStates',
      'fetchEmployers',
      'fetchProjectListFiltered',
      'fetchProjectsByEmployer',
      'fetchEmployersByClient',
      'fetchProjectsByClient',
      'fetchClients',
    ]),
    async openProjectsExport() {
      const url = await this.fetchProjectsExportUrl();
      window.open(url);
    },
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    projectStateColor(state) {
      return getProjectStateColor(state);
    },
    optionsYesNo() {
      return yesNo();
    },
    optionsProjectState() {
      return this.getProjectStates;
    },
    employerNames() {
      return this.getEmployers.map((employer) => {
        return employer.name;
      });
    },
    clientNames() {
      return this.getClients.map((client) => {
        return client.name;
      });
    },
    onFilter(filter) {
      const cachedFilters = JSON.parse(localStorage.getItem('projects-table-filters'));
      // only update if filters have changed
      if (
        isEqual(filter.filters, cachedFilters.filters) &&
        filter.sortField === cachedFilters.sortField &&
        filter.sortOrder === cachedFilters.sortOrder
      ) {
        return filter;
      }
      this.updateUserSettings({
        userId: this.getCurrentUser.id,
        settings: {
          name: 'projectsFilter',
          filters: filter.filters,
          sortField: filter.sortField,
          filterMatchModes: filter.filterMatchModes,
        },
      });
    },
    onRemoveFilters() {
      this.sortOrder = null;
      this.sortField = null;
      this.filters = {};
      this.updateUserSettings({
        userId: this.getCurrentUser.id,
        settings: {
          name: 'projectsFilter',
          filters: {},
          sortField: null,
          filterMatchModes: {},
        },
      });
    },
  },
  async created() {
    await this.fetchInstallationTeams();
    await this.fetchInstallationTypes();
    await this.fetchOriginalInstallationTypes();
    await this.fetchProjectStates();

    if (this.isClient) {
      await this.fetchEmployersByClient(this.getCurrentUser.client.id);
      await this.fetchProjectsByClient(this.getCurrentUser.client.id);
    } else if (this.isAdmin) {
      await this.fetchEmployers();
      await this.fetchClients();
      await this.fetchProjectList();
    }
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(n),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(n) {
  text-align: center;
}

.projects-component {
  border: 0px solid rebeccapurple;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
}

.fill-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
}
.flexbox-item-grow {
  flex: 1; /* same as flex: 1 1 auto; */
}

::v-deep .p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

/** Fix Dropdown overlay */
::v-deep .p-datatable-scrollable-header {
  overflow: visible;
}

.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}

// Table Header Filter Calendar Dropdown Width
::v-deep .p-calendar .p-datepicker {
  min-width: 25rem;
}
</style>
